// Dashboard.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/auth';
import { getAllClients, getPaymentsByClient } from '../utils/api';
import ClientList from '../components/ClientList';
import { formatDate } from '../utils/dateUtils';
import '../styles/Dashboard.css';

function Dashboard() {
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [clientPayments, setClientPayments] = useState({});
  const [filters, setFilters] = useState({
    searchQuery: '',
    dateRange: {
      contractStart: '',
      contractEnd: '',
      paymentStart: '',
      paymentEnd: '',
    },
    workTypes: [],
  });

  const navigate = useNavigate();
  const { logout } = useAuth();
  const role = localStorage.getItem('role');

  console.log('Current role in Dashboard:', role);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const clientList = await getAllClients();
        setClients(clientList);
        setFilteredClients(clientList);

        // Загружаем платежи для всех клиентов
        const payments = {};
        for (const client of clientList) {
          try {
            const response = await getPaymentsByClient(client.id);
            payments[client.id] = Array.isArray(response) ? response :
                                  Array.isArray(response?.payments) ? response.payments : [];
          } catch (error) {
            console.error(`Ошибка загрузки платежей для клиента ${client.id}:`, error);
            payments[client.id] = [];
          }
        }
        setClientPayments(payments);
      } catch (error) {
        console.error('Ошибка при загрузке клиентов:', error);
      }
    };
    fetchClients();
  }, []);

  // Получаем уникальные виды работ для фильтра
  const uniqueWorkTypes = [...new Set(clients.map(client => client.workType.split(',').map(type => type.trim())).flat())].filter(Boolean);

  const getNextPayment = (client) => {
    if (!client.contractAmount || !client.contractTerm) {
      return { date: null, amount: null };
    }

    const payments = clientPayments[client.id] || [];

    if (!Array.isArray(payments)) {
      return {
        date: new Date(client.contractDate),
        amount: (client.contractAmount - (client.prepaymentAmount || 0)) / client.contractTerm
      };
    }

    const paidMonths = new Set(payments.map(p => p.month));
    const totalPaid = payments.reduce((sum, p) => sum + (Number(p.amount) || 0), 0);
    const remainingDebt = client.contractAmount - (client.prepaymentAmount || 0) - totalPaid;
    const remainingMonths = client.contractTerm - paidMonths.size;

    if (remainingMonths <= 0 || remainingDebt <= 0) {
      return { date: null, amount: null };
    }

    const monthlyAmount = remainingDebt / remainingMonths;
    const lastPaidMonth = Math.max(...Array.from(paidMonths), 0);
    const nextPaymentDate = new Date(client.contractDate);
    nextPaymentDate.setMonth(nextPaymentDate.getMonth() + (lastPaidMonth + 1));

    return {
      date: nextPaymentDate,
      amount: monthlyAmount
    };
  };

  const calculateRemainingDebt = (client) => {
    const totalAmount = Number(client.contractAmount) || 0;
    const prepayment = Number(client.prepaymentAmount) || 0;
    const payments = clientPayments[client.id] || [];

    if (!Array.isArray(payments)) {
      return totalAmount - prepayment;
    }

    const totalPaid = payments.reduce((sum, payment) => {
      return sum + (Number(payment.amount) || 0);
    }, 0);

    return totalAmount - prepayment - totalPaid;
  };

  const applyFilters = () => {
    let result = [...clients];

    // Фильтр по поиску
    if (filters.searchQuery) {
      const searchLower = filters.searchQuery.toLowerCase();
      result = result.filter(client =>
        (client.fullName?.toLowerCase().includes(searchLower)) ||
        (client.phone?.toLowerCase().includes(searchLower)) ||
        (client.contractNumber?.toLowerCase().includes(searchLower))
      );
    }

    // Фильтр по дате договора
    if (filters.dateRange.contractStart) {
      result = result.filter(client =>
        new Date(client.contractDate) >= new Date(filters.dateRange.contractStart)
      );
    }
    if (filters.dateRange.contractEnd) {
      result = result.filter(client =>
        new Date(client.contractDate) <= new Date(filters.dateRange.contractEnd)
      );
    }

    // Фильтр по дате платежа
    if (filters.dateRange.paymentStart || filters.dateRange.paymentEnd) {
      result = result.filter(client => {
        const nextPayment = getNextPayment(client);
        if (!nextPayment.date) return false;

        const paymentDate = new Date(nextPayment.date);

        if (filters.dateRange.paymentStart) {
          const startDate = new Date(filters.dateRange.paymentStart);
          if (paymentDate < startDate) return false;
        }

        if (filters.dateRange.paymentEnd) {
          const endDate = new Date(filters.dateRange.paymentEnd);
          if (paymentDate > endDate) return false;
        }

        return true;
      });
    }

    // Фильтр по видам работ
    if (filters.workTypes.length > 0) {
      result = result.filter(client => {
        const clientWorkTypes = client.workType.split(',').map(type => type.trim());
        return filters.workTypes.some(filter => clientWorkTypes.includes(filter));
      });
    }

    setFilteredClients(result);
  };

  useEffect(() => {
    applyFilters();
  }, [filters, clients, clientPayments]);

  return (
    <div className="dashboard">
      <div className="sidebar">
        <div className="menu-container">
          <button onClick={() => navigate('/create-client')} className="menu-btn create-btn">
            <i className="fas fa-plus"></i>
            Создать клиента
          </button>
          <button onClick={() => navigate('/overdue')} className="menu-btn overdue-btn">
            <i className="fas fa-clock"></i>
            Обзор просрочек
          </button>
          <button onClick={() => navigate('/problematic')} className="menu-btn problematic-btn">
            <i className="fas fa-exclamation-triangle"></i>
            Проблемные клиенты
          </button>
          {role && role === 'chief_manager' && (
            <button onClick={() => navigate('/analytics')} className="menu-btn analytics-btn">
              <i className="fas fa-chart-bar"></i>
              Аналитика
            </button>
          )}
        </div>
        <div className="bottom-menu">
          <button onClick={() => logout()} className="menu-btn logout-btn">
            <i className="fas fa-sign-out-alt"></i>
            Выйти
          </button>
        </div>
      </div>

      <div className="main-content">
        <h1>Клиенты</h1>

        <div className="filters-section">
          <div className="search-filter">
            <input
              type="text"
              placeholder="Поиск по ФИО, телефону или номеру договора"
              value={filters.searchQuery}
              onChange={(e) => setFilters({ ...filters, searchQuery: e.target.value })}
              className="search-input"
            />
          </div>

          <div className="date-filters">
            <div className="date-range">
              <h4>Дата договора:</h4>
              <input
                type="date"
                value={filters.dateRange.contractStart}
                onChange={(e) => setFilters({
                  ...filters,
                  dateRange: { ...filters.dateRange, contractStart: e.target.value }
                })}
              />
              <span>-</span>
              <input
                type="date"
                value={filters.dateRange.contractEnd}
                onChange={(e) => setFilters({
                  ...filters,
                  dateRange: { ...filters.dateRange, contractEnd: e.target.value }
                })}
              />
            </div>

            <div className="date-range">
              <h4>Дата платежа:</h4>
              <input
                type="date"
                value={filters.dateRange.paymentStart}
                onChange={(e) => setFilters({
                  ...filters,
                  dateRange: { ...filters.dateRange, paymentStart: e.target.value }
                })}
              />
              <span>-</span>
              <input
                type="date"
                value={filters.dateRange.paymentEnd}
                onChange={(e) => setFilters({
                  ...filters,
                  dateRange: { ...filters.dateRange, paymentEnd: e.target.value }
                })}
              />
            </div>
          </div>

          {uniqueWorkTypes.length > 0 && (
            <div className="work-type-filter">
              <h4>Виды работ:</h4>
              <div className="work-types-container">
                {uniqueWorkTypes.map(type => (
                  <button
                    key={type}
                    className={`work-type-btn ${filters.workTypes.includes(type) ? 'active' : ''}`}
                    onClick={() => {
                      const newWorkTypes = filters.workTypes.includes(type)
                        ? filters.workTypes.filter(t => t !== type)
                        : [...filters.workTypes, type];
                      setFilters({ ...filters, workTypes: newWorkTypes });
                    }}
                  >
                    {type}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>

        <ClientList
          clients={filteredClients}
          clientPayments={clientPayments}
          getNextPayment={getNextPayment}
          calculateRemainingDebt={calculateRemainingDebt}
        />
      </div>
    </div>
  );
}

export default Dashboard;