// frontend/src/components/DocumentManager.js
import React, { useState } from 'react';
import { downloadDocument } from '../utils/api';

function DocumentManager({ documents, onUpload, onDelete }) {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => setSelectedFile(e.target.files[0]);

  const handleUpload = () => {
    if (selectedFile) {
      onUpload(selectedFile);
      setSelectedFile(null);
    }
  };

  const handleDownload = async (documentId) => {
    try {
      await downloadDocument(documentId);
    } catch (error) {
      console.error(`Ошибка при скачивании документа с ID ${documentId}:`, error);
    }
  };

  return (
    <div className="document-manager">
      <h2>Документы</h2>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload} disabled={!selectedFile}>Загрузить</button>
      <ul>
        {documents.map((doc) => (
          <li key={doc.id}>
            <span>Документ #{doc.sequentialId} ({doc.name})</span>
            <button onClick={() => handleDownload(doc.id)}>Скачать</button>
            <button onClick={() => onDelete(doc.id)}>Удалить</button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default DocumentManager;