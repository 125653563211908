// frontend/src/components/CompanyCard.js
import React, { useState, useEffect } from 'react';
import { uploadCompanyFile, downloadCompanyFile, getCompanyFileInfo } from '../utils/api';
import '../styles/CompanyCard.css';

function CompanyCard() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState('');
  const [fileInfo, setFileInfo] = useState(null);

  useEffect(() => {
    loadFileInfo();
  }, []);

  const loadFileInfo = async () => {
    try {
      const info = await getCompanyFileInfo();
      setFileInfo(info);
    } catch (error) {
      console.error("Ошибка при получении информации о файле:", error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && !file.name.toLowerCase().endsWith('.doc') && !file.name.toLowerCase().endsWith('.docx')) {
      setMessage('Пожалуйста, выберите файл Word (.doc или .docx)');
      setSelectedFile(null);
      e.target.value = '';
      return;
    }
    setSelectedFile(file);
    setMessage('');
  };

  const handleUpload = async () => {
    if (selectedFile) {
      try {
        await uploadCompanyFile(selectedFile);
        setMessage('Файл компании успешно загружен');
        setSelectedFile(null);
        await loadFileInfo();
      } catch (error) {
        setMessage('Ошибка при загрузке файла');
        console.error("Ошибка при загрузке файла компании:", error);
      }
    } else {
      setMessage('Пожалуйста, выберите файл для загрузки');
    }
  };

  const handleDownload = async () => {
    try {
      await downloadCompanyFile();
    } catch (error) {
      setMessage('Ошибка при скачивании файла');
      console.error("Ошибка при скачивании файла компании:", error);
    }
  };

  return (
    <div className="company-card">
      <h3>Карточка компании</h3>
      {fileInfo ? (
        <div className="file-info">
          <p>Текущий файл: {fileInfo.name}</p>
          <p>Загружен: {new Date(fileInfo.uploadDate).toLocaleString()}</p>
        </div>
      ) : (
        <p>Файл компании еще не загружен</p>
      )}
      <div className="file-upload">
        <input 
          type="file" 
          onChange={handleFileChange} 
          accept=".doc,.docx"
        />
        <button 
          onClick={handleUpload} 
          disabled={!selectedFile}
          className="upload-btn"
        >
          {fileInfo ? 'Обновить файл компании' : 'Загрузить файл компании'}
        </button>
      </div>
      {fileInfo && (
        <button 
          onClick={handleDownload}
          className="download-btn"
        >
          Скачать файл компании
        </button>
      )}
      {message && <p className={`message ${message.includes('Ошибка') ? 'error' : 'success'}`}>{message}</p>}
    </div>
  );
}

export default CompanyCard;