import React from 'react';
import { useAuth } from '../utils/auth';
import '../styles/ClientCard.css';

export const fieldLabels = {
  fullName: 'ФИО клиента',
  contractDate: 'Дата договора',
  contractNumber: 'Номер договора',
  phone: 'Телефон',
  address: 'Адрес',
  paymentType: 'Способ оплаты',
  workType: 'Вид работ',
  passportFile: 'Файл паспорта',
  contractAmount: 'Сумма договора',
  prepaymentAmount: 'Сумма предоплаты',
  contractTerm: 'Срок договора (месяцев)',
  status: 'Статус договора',
  isProblematic: 'Проблемный клиент',
  surveyorName: 'ФИО замерщика',
  surveyorSalary: 'Зарплата замерщика',
  managerName: 'ФИО менеджера',
  managerSalary: 'Зарплата менеджера',
  installerName: 'ФИО монтажника',
  installerSalary: 'Зарплата монтажника',
  materialCosts: 'Затраты на материалы',
  garbageDisposalCost: 'Вынос мусора',
  deliveryCost: 'Доставка',
  liftingCost: 'Подъем',
  additionalExpenses: 'Дополнительные расходы',
  remainingDebt: 'Остаток задолженности',
  paidAmount: 'Оплаченная сумма',
  monthlyPaymentAmount: 'Сумма ежемесячного платежа',
  notes: 'Примечания'
};

export const reverseFieldLabels = Object.entries(fieldLabels).reduce((acc, [key, value]) => {
  acc[value] = key;
  return acc;
}, {});

function ClientCard({ client, onEdit }) {
  const role = localStorage.getItem('role'); // изменено
  console.log('Current role in ClientCard:', role); // для отладки
  
  if (!client) {
    return <div className="loading-state">Загрузка данных...</div>;
  }

  const calculateRemainingDebt = () => {
    const contractAmount = parseFloat(client.contractAmount) || 0;
    const prepaymentAmount = parseFloat(client.prepaymentAmount) || 0;
    const paidAmount = parseFloat(client.paidAmount) || 0;
    return contractAmount - prepaymentAmount - paidAmount;
  };

  const calculateTotalAdditionalExpenses = () => {
    const garbageDisposalCost = parseFloat(client.garbageDisposalCost) || 0;
    const deliveryCost = parseFloat(client.deliveryCost) || 0;
    const liftingCost = parseFloat(client.liftingCost) || 0;
    return garbageDisposalCost + deliveryCost + liftingCost;
  };

  const formatNumber = (number) => {
    if (number === null || number === undefined || isNaN(number)) return '—';
    return new Intl.NumberFormat('ru-RU', { 
      minimumFractionDigits: 2,
      maximumFractionDigits: 2 
    }).format(number);
  };

  const sections = {
    'Основная информация': [
      { field: 'fullName', highlight: false },
      { field: 'contractDate', highlight: false },
      { field: 'contractNumber', highlight: false },
      { field: 'phone', highlight: false },
      { field: 'address', highlight: false }
    ],
    'Договор': [
      { field: 'paymentType', highlight: false },
      { field: 'workType', highlight: false },
      { field: 'contractAmount', highlight: true },
      { field: 'prepaymentAmount', highlight: false },
      { field: 'contractTerm', highlight: false },
      { field: 'status', highlight: false },
      { field: 'isProblematic', highlight: false }
    ],
    'Сотрудники': [
      { field: 'surveyorName', highlight: false },
      { field: 'surveyorSalary', highlight: false },
      { field: 'managerName', highlight: false },
      { field: 'managerSalary', highlight: false },
      { field: 'installerName', highlight: false },
      { field: 'installerSalary', highlight: false }
    ],
    'Финансы': [
      { field: 'materialCosts', highlight: false },
      { field: 'garbageDisposalCost', highlight: false },
      { field: 'deliveryCost', highlight: false },
      { field: 'liftingCost', highlight: false },
      { field: 'additionalExpenses', highlight: true },
      { field: 'remainingDebt', highlight: true },
      { field: 'paidAmount', highlight: true },
      { field: 'monthlyPaymentAmount', highlight: true }
    ],
    'Дополнительно': [
      { field: 'notes', highlight: false }
    ]
  };

  const formatFieldValue = (field, value) => {
    if (value === null || value === undefined) return '—';
    
    switch (field) {
      case 'contractDate':
        return value ? new Date(value).toLocaleDateString('ru-RU') : '—';
      case 'contractAmount':
      case 'prepaymentAmount':
      case 'surveyorSalary':
      case 'managerSalary':
      case 'installerSalary':
      case 'materialCosts':
      case 'garbageDisposalCost':
      case 'deliveryCost':
      case 'liftingCost':
      case 'remainingDebt':
      case 'paidAmount':
      case 'monthlyPaymentAmount':
        return `${formatNumber(parseFloat(value) || 0)} ₽`;
      case 'additionalExpenses':
        return `${formatNumber(calculateTotalAdditionalExpenses())} ₽`;
      case 'contractTerm':
        return value ? `${value} месяцев` : '—';
      case 'isProblematic':
        return value ? 'Да' : 'Нет';
      default:
        return value || '—';
    }
  };

  return (
    <div className="client-card">
      <div className="card-header">
        <h2>Информация о клиенте</h2>
        {role && role === 'chief_manager' && (
          <button className="edit-button" onClick={onEdit}>
            <i className="fas fa-edit"></i>
            Редактировать
          </button>
        )}
      </div>
      
      <div className="sections-grid">
        {Object.entries(sections).map(([sectionTitle, fields]) => (
          <div key={sectionTitle} className="info-section">
            <h3>{sectionTitle}</h3>
            <div className="fields-grid">
              {fields.map(({ field, highlight }) => (
                <div key={field} className={`field ${highlight ? 'highlighted' : ''}`}>
                  <label>{fieldLabels[field]}</label>
                  <span className="value">
                    {field === 'remainingDebt' 
                      ? `${formatNumber(calculateRemainingDebt())} ₽`
                      : formatFieldValue(field, client[field])}
                  </span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ClientCard;