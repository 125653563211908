import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDate, formatCurrency } from '../utils/dateUtils';
import { getPaymentsByClient } from '../utils/api';
import '../styles/ClientList.css';

function ClientList({ clients }) {
  const navigate = useNavigate();
  const [clientPayments, setClientPayments] = useState({});

  useEffect(() => {
    const loadPayments = async () => {
      const payments = {};
      for (const client of clients) {
        try {
          const response = await getPaymentsByClient(client.id);
          // Сохраняем массив платежей
          payments[client.id] = Array.isArray(response) ? response : 
                               Array.isArray(response?.payments) ? response.payments : [];
        } catch (error) {
          console.error(`Ошибка загрузки платежей для клиента ${client.id}:`, error);
          payments[client.id] = [];
        }
      }
      setClientPayments(payments);
    };

    loadPayments();
  }, [clients]);

  const calculateRemainingDebt = (client) => {
    const totalAmount = Number(client.contractAmount) || 0;
    const prepayment = Number(client.prepaymentAmount) || 0;
    const payments = clientPayments[client.id] || [];
    
    // Проверяем, что payments это массив
    if (!Array.isArray(payments)) {
      console.warn('Платежи не являются массивом:', payments);
      return totalAmount - prepayment;
    }

    const totalPaid = payments.reduce((sum, payment) => {
      return sum + (Number(payment.amount) || 0);
    }, 0);

    return totalAmount - prepayment - totalPaid;
  };

  const getNextPayment = (client) => {
    if (!client.contractAmount || !client.contractTerm) {
      return { date: null, amount: null };
    }

    const payments = clientPayments[client.id] || [];
    
    // Проверяем, что payments это массив
    if (!Array.isArray(payments)) {
      console.warn('Платежи не являются массивом:', payments);
      return {
        date: new Date(client.contractDate),
        amount: (client.contractAmount - (client.prepaymentAmount || 0)) / client.contractTerm
      };
    }

    // Получаем уникальные оплаченные месяцы
    const paidMonths = new Set(payments.map(p => p.month));
    
    // Считаем общую сумму платежей
    const totalPaid = payments.reduce((sum, p) => sum + (Number(p.amount) || 0), 0);
    
    // Считаем остаток долга
    const remainingDebt = client.contractAmount - (client.prepaymentAmount || 0) - totalPaid;
    
    // Считаем оставшиеся месяцы
    const remainingMonths = client.contractTerm - paidMonths.size;

    if (remainingMonths <= 0 || remainingDebt <= 0) {
      return { date: null, amount: null };
    }

    // Рассчитываем новый ежемесячный платеж
    const monthlyAmount = remainingDebt / remainingMonths;

    // Определяем дату следующего платежа
    const lastPaidMonth = Math.max(...Array.from(paidMonths), 0);
    const nextPaymentDate = new Date(client.contractDate);
    nextPaymentDate.setMonth(nextPaymentDate.getMonth() + (lastPaidMonth + 1));

    return {
      date: nextPaymentDate,
      amount: monthlyAmount
    };
  };

  return (
    <div className="table-container">
      <table className="client-list">
        <thead>
          <tr>
            <th>Дата договора</th>
            <th>ФИО</th>
            <th>Адрес</th>
            <th>Телефон</th>
            <th>Вид работ</th>
            <th>Остаток задолженности</th>
            <th>Дата ближайшего платежа</th>
            <th>Сумма месячного платежа</th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client) => {
            const remainingDebt = calculateRemainingDebt(client);
            const nextPayment = getNextPayment(client);
            return (
              <tr key={client.id} onClick={() => navigate(`/client/${client.id}`)}>
                <td>{formatDate(client.contractDate)}</td>
                <td>{client.fullName}</td>
                <td>{client.address}</td>
                <td>{client.phone}</td>
                <td>{client.workType}</td>
                <td>{formatCurrency(remainingDebt)}</td>
                <td>{nextPayment.date ? formatDate(nextPayment.date) : 'Нет данных'}</td>
                <td>{nextPayment.amount ? formatCurrency(nextPayment.amount) : 'Нет данных'}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ClientList;