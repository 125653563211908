import React, { useState } from 'react';
import '../styles/ChangeLog.css';

const formatDate = (dateString) => {
  const options = { 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric', 
    hour: '2-digit', 
    minute: '2-digit' 
  };
  return new Date(dateString).toLocaleDateString('ru-RU', options);
};

const formatValue = (value) => {
  if (value === null || value === undefined) return '—';
  if (typeof value === 'boolean') return value ? 'Да' : 'Нет';
  if (typeof value === 'number') return value.toLocaleString('ru-RU');
  return value;
};

const getActionDescription = (change) => {
  switch (change.type) {
    case 'PAYMENT':
      return `Внесен платеж на сумму ${formatValue(change.details?.amount)} руб.`;
    case 'INFO_UPDATE':
      if (change.field && change.oldValue && change.newValue) {
        return `${change.field}: изменено с "${formatValue(change.oldValue)}" на "${formatValue(change.newValue)}"`;
      }
      return change.action;
    case 'NOTE_UPDATE':
      return 'Обновлены заметки';
    case 'DOCUMENT':
      return `${change.action}${change.details?.fileName ? ` (${change.details.fileName})` : ''}`;
    default:
      return change.action;
  }
};

function ChangeLog({ changes = [] }) {
  const [filterType, setFilterType] = useState('ALL');

  const filteredChanges = filterType === 'ALL' 
    ? changes 
    : changes.filter(change => change.type === filterType);

  if (!Array.isArray(changes)) {
    return <div className="change-log-error">Ошибка загрузки истории изменений</div>;
  }

  return (
    <div className="change-log">
      <div className="change-log-header">
        <h3>История изменений</h3>
        <select 
          value={filterType} 
          onChange={(e) => setFilterType(e.target.value)}
          className="filter-select"
        >
          <option value="ALL">Все изменения</option>
          <option value="PAYMENT">Платежи</option>
          <option value="INFO_UPDATE">Изменение данных</option>
          <option value="NOTE_UPDATE">Заметки</option>
          <option value="DOCUMENT">Документы</option>
        </select>
      </div>

      {filteredChanges.length === 0 ? (
        <div className="no-changes">Нет записей об изменениях</div>
      ) : (
        <div className="change-log-table">
          <table>
            <thead>
              <tr>
                <th>Дата и время</th>
                <th>Действие</th>
                <th>Менеджер</th>
              </tr>
            </thead>
            <tbody>
              {filteredChanges.map((change) => (
                <tr key={change.id} className={`type-${change.type.toLowerCase()}`}>
                  <td>{formatDate(change.timestamp)}</td>
                  <td>{getActionDescription(change)}</td>
                  <td>
                    <div className="manager-info">
                      <span className="manager-name">{change.managerName}</span>
                      <span className="manager-id">ID: {change.managerId}</span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default ChangeLog;