import React, { useEffect, useState } from 'react';
import { getAllClients } from '../utils/api';
import ClientList from '../components/ClientList';
import '../styles/Dashboard.css';

function ProblematicClients() {
  const [problematicClients, setProblematicClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProblematicClients = async () => {
      try {
        const allClients = await getAllClients();
        const problematic = allClients.filter(client => client.isProblematic);
        setProblematicClients(problematic);
        setIsLoading(false);
      } catch (error) {
        console.error('Ошибка при загрузке клиентов:', error);
        setIsLoading(false);
      }
    };

    fetchProblematicClients();
  }, []);

  return (
    <div className="dashboard">
      <div className="main-content">
        <h1>Проблемные клиенты</h1>
        {isLoading ? (
          <p>Загрузка...</p>
        ) : (
          <ClientList clients={problematicClients} />
        )}
      </div>
    </div>
  );
}

export default ProblematicClients;