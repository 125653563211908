import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createClient } from '../utils/api';
import '../styles/CreateClient.css';

function CreateClient() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: '',
    phone: '',
    address: '',
    contractDate: '',
    contractNumber: '',
    workType: '',
    paymentType: '',
    contractAmount: '',
    prepaymentAmount: '',
    contractTerm: '',
    status: '',
    isProblematic: false,
    surveyorName: '',
    surveyorSalary: '',
    managerName: '',
    managerSalary: '',
    installerName: '',
    installerSalary: '',
    materialCosts: '',
    garbageDisposalCost: '',
    deliveryCost: '',
    liftingCost: '',
    additionalExpenses: '',
    remainingDebt: '',
    paidAmount: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    setFormData(prev => {
      const newData = {
        ...prev,
        [name]: type === 'checkbox' ? checked : value,
      };

      // Автоматически обновляем общие доп. расходы
      if (['garbageDisposalCost', 'deliveryCost', 'liftingCost'].includes(name)) {
        const garbageDisposalCost = parseFloat(name === 'garbageDisposalCost' ? value : prev.garbageDisposalCost) || 0;
        const deliveryCost = parseFloat(name === 'deliveryCost' ? value : prev.deliveryCost) || 0;
        const liftingCost = parseFloat(name === 'liftingCost' ? value : prev.liftingCost) || 0;
        
        newData.additionalExpenses = (garbageDisposalCost + deliveryCost + liftingCost).toFixed(2);
      }

      return newData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const cleanedData = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => {
        if (typeof value === 'string' && value.trim() === '') return [key, null];
        if (typeof value === 'string' && !isNaN(value)) return [key, parseFloat(value)];
        return [key, value];
      })
    );

    try {
      await createClient(cleanedData);
      navigate('/');
    } catch (error) {
      console.error('Ошибка при создании клиента:', error);
    }
  };

  return (
    <div className="create-client">
      <h1>Создать клиента</h1>
      <form onSubmit={handleSubmit} className="create-client-form">
        <div className="form-section">
          <h2>Основная информация</h2>
          <div className="form-grid">
            <div className="form-group">
              <label>ФИО</label>
              <input
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Телефон</label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Адрес</label>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
              />
            </div>
          </div>
        </div>

        <div className="form-section">
          <h2>Информация о договоре</h2>
          <div className="form-grid">
            <div className="form-group">
              <label>Дата договора</label>
              <input
                type="date"
                name="contractDate"
                value={formData.contractDate}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Номер договора</label>
              <input
                type="text"
                name="contractNumber"
                value={formData.contractNumber}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Вид работ</label>
              <input
                type="text"
                name="workType"
                value={formData.workType}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Вид оплаты</label>
              <input
                type="text"
                name="paymentType"
                value={formData.paymentType}
                onChange={handleChange}
                required
              />
            </div>
          </div>
        </div>

        <div className="form-section">
          <h2>Финансовая информация</h2>
          <div className="form-grid">
            <div className="form-group">
              <label>Сумма договора</label>
              <input
                type="number"
                name="contractAmount"
                value={formData.contractAmount}
                onChange={handleChange}
                required
                step="0.01"
              />
            </div>
            <div className="form-group">
              <label>Предоплата</label>
              <input
                type="number"
                name="prepaymentAmount"
                value={formData.prepaymentAmount}
                onChange={handleChange}
                step="0.01"
              />
            </div>
            <div className="form-group">
              <label>Срок (в месяцах)</label>
              <input
                type="number"
                name="contractTerm"
                value={formData.contractTerm}
                onChange={handleChange}
                required
              />
            </div>
          </div>
        </div>

        <div className="form-section">
          <h2>Дополнительные расходы</h2>
          <div className="form-grid">
            <div className="form-group">
              <label>Вынос мусора</label>
              <input
                type="number"
                name="garbageDisposalCost"
                value={formData.garbageDisposalCost}
                onChange={handleChange}
                step="0.01"
              />
            </div>
            <div className="form-group">
              <label>Доставка</label>
              <input
                type="number"
                name="deliveryCost"
                value={formData.deliveryCost}
                onChange={handleChange}
                step="0.01"
              />
            </div>
            <div className="form-group">
              <label>Подъем</label>
              <input
                type="number"
                name="liftingCost"
                value={formData.liftingCost}
                onChange={handleChange}
                step="0.01"
              />
            </div>
          </div>
          <div className="total-expenses">
            <strong>Итого дополнительные расходы:</strong>
            <span>{formData.additionalExpenses || '0.00'} руб.</span>
          </div>
        </div>

        <div className="form-section">
          <h2>Статус</h2>
          <div className="form-grid">
            <div className="form-group">
              <label>Статус договора</label>
              <select 
                name="status" 
                value={formData.status} 
                onChange={handleChange}
                required
              >
                <option value="">Выберите статус</option>
                <option value="active">Активный</option>
                <option value="completed">Завершен</option>
                <option value="canceled">Отменен</option>
              </select>
            </div>
            <div className="form-group checkbox-group">
              <label>
                <input
                  type="checkbox"
                  name="isProblematic"
                  checked={formData.isProblematic}
                  onChange={handleChange}
                />
                <span>Проблемный клиент</span>
              </label>
            </div>
          </div>
        </div>

        <div className="form-section">
          <h2>Информация о сотрудниках</h2>
          <div className="form-grid">
            <div className="form-group">
              <label>Имя замерщика</label>
              <input
                type="text"
                name="surveyorName"
                value={formData.surveyorName}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Зарплата замерщика</label>
              <input
                type="number"
                name="surveyorSalary"
                value={formData.surveyorSalary}
                onChange={handleChange}
                step="0.01"
              />
            </div>
            <div className="form-group">
              <label>Имя менеджера</label>
              <input
                type="text"
                name="managerName"
                value={formData.managerName}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Зарплата менеджера</label>
              <input
                type="number"
                name="managerSalary"
                value={formData.managerSalary}
                onChange={handleChange}
                step="0.01"
              />
            </div>
            <div className="form-group">
              <label>Имя монтажника</label>
              <input
                type="text"
                name="installerName"
                value={formData.installerName}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Зарплата монтажника</label>
              <input
                type="number"
                name="installerSalary"
                value={formData.installerSalary}
                onChange={handleChange}
                step="0.01"
              />
            </div>
          </div>
        </div>

        <div className="form-section">
          <h2>Дополнительная информация</h2>
          <div className="form-grid">
            <div className="form-group">
              <label>Расходы на материалы</label>
              <input
                type="number"
                name="materialCosts"
                value={formData.materialCosts}
                onChange={handleChange}
                step="0.01"
              />
            </div>
          </div>
        </div>

        <button type="submit" className="submit-button">
          Создать клиента
        </button>
      </form>
    </div>
  );
}

export default CreateClient;