import React, { useState, useEffect } from 'react';
import '../styles/Analytics.css';

const Analytics = ({ data, onUpdate }) => {
  const [editableData, setEditableData] = useState(data);

  useEffect(() => {
    setEditableData(data);
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    setEditableData(prev => {
      const newData = {
        ...prev,
        [name]: parseFloat(value) || 0,
      };

      if (['garbageDisposalCost', 'deliveryCost', 'liftingCost'].includes(name)) {
        const garbageDisposalCost = name === 'garbageDisposalCost' ? parseFloat(value) : (prev.garbageDisposalCost || 0);
        const deliveryCost = name === 'deliveryCost' ? parseFloat(value) : (prev.deliveryCost || 0);
        const liftingCost = name === 'liftingCost' ? parseFloat(value) : (prev.liftingCost || 0);
        
        newData.additionalExpenses = garbageDisposalCost + deliveryCost + liftingCost;
      }

      return newData;
    });
  };

  const calculateActualPaidAmount = () => {
    if (!data.payments) return 0;
    return data.payments.reduce((sum, payment) => sum + payment.amount, 0);
  };

  const calculateRemaining = () => {
    const actualPaidAmount = calculateActualPaidAmount();
    const {
      contractAmount = 0,
      prepaymentAmount = 0,
    } = editableData;

    return contractAmount - prepaymentAmount - actualPaidAmount;
  };

  const calculateTotalAdditionalExpenses = () => {
    const {
      garbageDisposalCost = 0,
      deliveryCost = 0,
      liftingCost = 0
    } = editableData;

    return garbageDisposalCost + deliveryCost + liftingCost;
  };

  const calculateProfit = () => {
    const {
      contractAmount = 0,
      managerSalary = 0,
      surveyorSalary = 0,
      installerSalary = 0,
      materialCosts = 0,
    } = editableData;

    const totalAdditionalExpenses = calculateTotalAdditionalExpenses();

    return contractAmount - managerSalary - surveyorSalary - 
           installerSalary - materialCosts;
  };

  const totalWithAdditionalExpenses = () => {
    return (editableData.contractAmount || 0) + calculateTotalAdditionalExpenses();
  };

  const handleSave = () => {
    onUpdate(editableData);
  };

  const actualPaidAmount = calculateActualPaidAmount();

  return (
    <div className="analytics-container">
      <h2 className="analytics-title">Аналитика клиента</h2>
      <div className="analytics-wrapper">
        <table className="analytics-data-table">
          <tbody>
            <tr className="analytics-data-row">
              <td className="analytics-data-cell analytics-label-cell">Сумма договора:</td>
              <td className="analytics-data-cell">
                <input
                  type="number"
                  name="contractAmount"
                  value={editableData.contractAmount || 0}
                  onChange={handleChange}
                  step="0.01"
                  className="analytics-input"
                />
              </td>
            </tr>
            <tr className="analytics-data-row">
              <td className="analytics-data-cell analytics-label-cell">Предоплата:</td>
              <td className="analytics-data-cell">
                <input
                  type="number"
                  name="prepaymentAmount"
                  value={editableData.prepaymentAmount || 0}
                  onChange={handleChange}
                  step="0.01"
                  className="analytics-input"
                />
              </td>
            </tr>
            
            <tr className="analytics-data-row">
              
            </tr>
            <tr className="analytics-data-row">
              <td className="analytics-data-cell analytics-label-cell">Вынос мусора:</td>
              <td className="analytics-data-cell">
                <input
                  type="number"
                  name="garbageDisposalCost"
                  value={editableData.garbageDisposalCost || 0}
                  onChange={handleChange}
                  step="0.01"
                  className="analytics-input"
                />
              </td>
            </tr>
            <tr className="analytics-data-row">
              <td className="analytics-data-cell analytics-label-cell">Доставка:</td>
              <td className="analytics-data-cell">
                <input
                  type="number"
                  name="deliveryCost"
                  value={editableData.deliveryCost || 0}
                  onChange={handleChange}
                  step="0.01"
                  className="analytics-input"
                />
              </td>
            </tr>
            <tr className="analytics-data-row">
              <td className="analytics-data-cell analytics-label-cell">Подъем:</td>
              <td className="analytics-data-cell">
                <input
                  type="number"
                  name="liftingCost"
                  value={editableData.liftingCost || 0}
                  onChange={handleChange}
                  step="0.01"
                  className="analytics-input"
                />
              </td>
            </tr>
            <tr className="analytics-data-row analytics-total-row">
              <td className="analytics-data-cell analytics-label-cell">Итого доп. расходы:</td>
              <td className="analytics-data-cell">{calculateTotalAdditionalExpenses().toFixed(2)}</td>
            </tr>
            
            <tr className="analytics-data-row">
              <td className="analytics-data-cell analytics-label-cell">Сумма договора с доп. расходами:</td>
              <td className="analytics-data-cell">{totalWithAdditionalExpenses().toFixed(2)}</td>
            </tr>
            <tr className="analytics-data-row">
              <td className="analytics-data-cell analytics-label-cell">Остаток долга:</td>
              <td className="analytics-data-cell">{calculateRemaining().toFixed(2)}</td>
            </tr>
            <tr className="analytics-data-row">
              <td className="analytics-data-cell analytics-label-cell">Уплачено:</td>
              <td className="analytics-data-cell">{actualPaidAmount.toFixed(2)}</td>
            </tr>
            
            <tr className="analytics-data-row">
              <td className="analytics-data-cell analytics-label-cell">Материалы:</td>
              <td className="analytics-data-cell">
                <input
                  type="number"
                  name="materialCosts"
                  value={editableData.materialCosts || 0}
                  onChange={handleChange}
                  step="0.01"
                  className="analytics-input"
                />
              </td>
            </tr>
            
            <tr className="analytics-data-row">
              <td className="analytics-data-cell analytics-label-cell">
                Зарплата менеджера ({editableData.managerName || '—'}):
              </td>
              <td className="analytics-data-cell">
                <input
                  type="number"
                  name="managerSalary"
                  value={editableData.managerSalary || 0}
                  onChange={handleChange}
                  step="0.01"
                  className="analytics-input"
                />
              </td>
            </tr>
            <tr className="analytics-data-row">
              <td className="analytics-data-cell analytics-label-cell">
                Зарплата замерщика ({editableData.surveyorName || '—'}):
              </td>
              <td className="analytics-data-cell">
                <input
                  type="number"
                  name="surveyorSalary"
                  value={editableData.surveyorSalary || 0}
                  onChange={handleChange}
                  step="0.01"
                  className="analytics-input"
                />
              </td>
            </tr>
            <tr className="analytics-data-row">
              <td className="analytics-data-cell analytics-label-cell">
                Зарплата монтажника ({editableData.installerName || '—'}):
              </td>
              <td className="analytics-data-cell">
                <input
                  type="number"
                  name="installerSalary"
                  value={editableData.installerSalary || 0}
                  onChange={handleChange}
                  step="0.01"
                  className="analytics-input"
                />
              </td>
            </tr>
            <tr className="analytics-data-row analytics-profit-row">
              <td className="analytics-data-cell analytics-label-cell">Прибыль:</td>
              <td className="analytics-data-cell">{calculateProfit().toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
        
        <button onClick={handleSave} className="analytics-save-btn">
          Сохранить изменения
        </button>
      </div>
    </div>
  );
};

export default Analytics;