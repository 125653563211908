import React, { useState, useEffect } from 'react';
import { 
  LineChart, Line, BarChart, Bar, PieChart, Pie, 
  XAxis, YAxis, CartesianGrid, Tooltip, Legend, 
  ResponsiveContainer, Cell 
} from 'recharts';
import { getGlobalAnalytics } from '../utils/api';
import '../styles/GlobalAnalytics.css';

const GlobalAnalytics = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    summary: {
      totalRevenue: 0,
      activeClients: 0,
      averageCheck: 0,
      totalProfit: 0,
      marginality: 0,
      marginalityChange: 0,
      averageProfit: 0,
      roi: 0
    },
    monthlyRevenue: [],
    clientStats: {
      byStatus: [],
      newByMonth: []
    },
    paymentStats: {
      byMonth: [],
      byMethod: []
    },
    profitStats: []
  });
  const [dateRange, setDateRange] = useState('year');
  const [view, setView] = useState('revenue');

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

  useEffect(() => {
    fetchAnalyticsData();
  }, [dateRange]);

  const fetchAnalyticsData = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await getGlobalAnalytics(dateRange);
      
      const normalizedData = {
        summary: {
          totalRevenue: response?.summary?.totalRevenue || 0,
          activeClients: response?.summary?.activeClients || 0,
          averageCheck: response?.summary?.averageCheck || 0,
          totalProfit: response?.summary?.totalProfit || 0,
          marginality: response?.summary?.marginality || 0,
          marginalityChange: response?.summary?.marginalityChange || 0,
          averageProfit: response?.summary?.averageProfit || 0,
          roi: response?.summary?.roi || 0
        },
        monthlyRevenue: Array.isArray(response?.monthlyRevenue) ? response.monthlyRevenue : [],
        clientStats: {
          byStatus: Array.isArray(response?.clientStats?.byStatus) ? response.clientStats.byStatus : [],
          newByMonth: Array.isArray(response?.clientStats?.newByMonth) ? response.clientStats.newByMonth : []
        },
        paymentStats: {
          byMonth: Array.isArray(response?.paymentStats?.byMonth) ? response.paymentStats.byMonth : [],
          byMethod: Array.isArray(response?.paymentStats?.byMethod) ? response.paymentStats.byMethod : []
        },
        profitStats: Array.isArray(response?.profitStats) ? response.profitStats : []
      };

      setData(normalizedData);
    } catch (err) {
      setError(err.message);
      console.error('Error fetching analytics:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleExport = async () => {
    try {
      const response = await fetch('https://skyneocrm.ru/api/analytics/export-clients', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
      });
  
      if (!response.ok) {
        throw new Error(`Ошибка HTTP: ${response.status}`);
      }
  
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      
      // Получаем имя файла из заголовков, если есть
      const contentDisposition = response.headers.get('content-disposition');
      const fileName = contentDisposition
        ? contentDisposition.split('filename=')[1].replace(/"/g, '')
        : 'clients-export.xlsx';
      
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
  
    } catch (error) {
      console.error('Ошибка при экспорте данных:', error);
      alert('Ошибка при экспорте базы клиентов: ' + error.message);
    }
  };

  const renderRevenueChart = () => {
    if (!data.monthlyRevenue.length) {
      return <div className="no-data">Нет данных о выручке</div>;
    }

    return (
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data.monthlyRevenue}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip 
            formatter={(value) => `${value.toLocaleString()} ₽`}
            labelFormatter={(label) => `Период: ${label}`}
          />
          <Legend />
          <Line 
            type="monotone" 
            dataKey="revenue" 
            stroke="#8884d8" 
            name="Выручка"
            isAnimationActive={false}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  };

  const renderClientChart = () => {
    if (!data.clientStats.byStatus.length) {
      return <div className="no-data">Нет данных о клиентах</div>;
    }

    return (
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data.clientStats.byStatus}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={100}
            label
            isAnimationActive={false}
          >
            {data.clientStats.byStatus.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip formatter={(value) => value.toLocaleString()} />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    );
  };

  const renderPaymentChart = () => {
    if (!data.paymentStats.byMonth.length) {
      return <div className="no-data">Нет данных о платежах</div>;
    }

    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data.paymentStats.byMonth}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip formatter={(value) => `${value.toLocaleString()} ₽`} />
          <Legend />
          <Bar dataKey="onTime" fill="#82ca9d" name="Вовремя" />
          <Bar dataKey="late" fill="#ff7777" name="Просрочено" />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  if (loading) {
    return (
      <div className="global-analytics">
        <div className="loading">Загрузка аналитики...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="global-analytics">
        <div className="error">
          <h3>Ошибка при загрузке данных</h3>
          <p>{error}</p>
          <button onClick={fetchAnalyticsData} className="retry-button">
            Попробовать снова
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="global-analytics">
      <div className="analytics-header">
        <h1>Общая аналитика</h1>
        <div className="controls">
          <select 
            value={dateRange} 
            onChange={(e) => setDateRange(e.target.value)}
            className="date-range-select"
          >
            <option value="year">За год</option>
            <option value="quarter">За квартал</option>
            <option value="month">За месяц</option>
          </select>
          <select 
            value={view} 
            onChange={(e) => setView(e.target.value)}
            className="view-select"
          >
            <option value="revenue">Выручка</option>
            <option value="clients">Клиенты</option>
            <option value="payments">Платежи</option>
          </select>
          <button 
            onClick={handleExport}
            className="export-button"
          >
            Экспорт базы клиентов
          </button>
        </div>
      </div>

      <div className="summary-cards">
        <div className="summary-card">
          <h3>Общая выручка</h3>
          <p>{data.summary.totalRevenue.toLocaleString()} ₽</p>
        </div>
        <div className="summary-card">
          <h3>Активные клиенты</h3>
          <p>{data.summary.activeClients.toLocaleString()}</p>
        </div>
        <div className="summary-card">
          <h3>Средний чек</h3>
          <p>{data.summary.averageCheck.toLocaleString()} ₽</p>
        </div>
        <div className="summary-card">
          <h3>Прибыль</h3>
          <p>{data.summary.totalProfit.toLocaleString()} ₽</p>
        </div>
      </div>

      <div className="charts-grid">
        {view === 'revenue' && (
          <div className="chart-container">
            <h3>Динамика выручки</h3>
            {renderRevenueChart()}
          </div>
        )}

        {view === 'clients' && (
          <div className="chart-container">
            <h3>Распределение клиентов</h3>
            {renderClientChart()}
          </div>
        )}

        {view === 'payments' && (
          <div className="chart-container">
            <h3>Статистика платежей</h3>
            {renderPaymentChart()}
          </div>
        )}
      </div>
    </div>
  );
};

export default GlobalAnalytics;