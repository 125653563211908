
// frontend/src/utils/dateUtils.js
export const formatDate = (dateString) => {
  if (!dateString) return 'Нет данных';
  return new Date(dateString).toLocaleDateString('ru-RU');
};

export const formatCurrency = (amount) => {
  if (amount == null) return 'Нет данных';
  return new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(amount);
};