import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllClients, getPaymentsByClient, updateClient } from '../utils/api';
import '../styles/OverdueClients.css';

const OverdueClients = () => {
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState({
    overdue: false,
    upcoming: false
  });

  const loadClients = async () => {
    try {
      setIsLoading(true);
      const allClients = await getAllClients();
      
      const installmentClients = allClients.filter(client => {
        if (!client.paymentType) return false;
        return client.paymentType.toLowerCase().indexOf('рассрочка') !== -1;
      });

      const clientsWithData = await Promise.all(
        installmentClients.map(async (client) => {
          try {
            const response = await getPaymentsByClient(client.id);
            const payments = Array.isArray(response) ? response : 
                           Array.isArray(response?.payments) ? response.payments : [];

            const today = new Date();
            let nextPaymentDate = null;
            let daysUntilPayment = null;
            let nextPaymentAmount = 0;
            let isOverdue = false;

            if (client.contractDate) {
              const contractDate = new Date(client.contractDate);
              const monthlyPayment = client.monthlyPaymentAmount || 
                (client.contractAmount - (client.prepaymentAmount || 0)) / (client.contractTerm || 1);

              const sortedPayments = [...payments].sort((a, b) => b.month - a.month);
              const lastPaidMonth = sortedPayments[0]?.month || 0;

              nextPaymentDate = new Date(contractDate);
              nextPaymentDate.setMonth(nextPaymentDate.getMonth() + lastPaidMonth + 1);
              
              daysUntilPayment = Math.ceil((nextPaymentDate - today) / (1000 * 60 * 60 * 24));
              nextPaymentAmount = monthlyPayment;
              
              isOverdue = daysUntilPayment < 0 && client.remainingDebt > 0;
            }

            return {
              ...client,
              nextPaymentDate,
              daysUntilPayment,
              nextPaymentAmount,
              isOverdue
            };
          } catch (error) {
            console.error(`Ошибка при загрузке платежей для клиента ${client.id}:`, error);
            return client;
          }
        })
      );

      setClients(clientsWithData);
    } catch (error) {
      console.error('Ошибка при загрузке клиентов:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadClients();
  }, []);

  const handleClientClick = (clientId) => {
    navigate(`/client/${clientId}`);
  };

  const handleToggleProblematic = async (e, client) => {
    e.stopPropagation();
    try {
      await updateClient(client.id, { isProblematic: !client.isProblematic });
      await loadClients();
    } catch (error) {
      console.error('Ошибка при обновлении статуса клиента:', error);
    }
  };

  const getFilteredClients = () => {
    return clients.filter(client => {
      // Поисковый фильтр
      const searchString = [
        client.fullName,
        client.contractNumber,
        client.phone
      ].filter(Boolean).join(' ').toLowerCase();
      
      const matchesSearch = !searchQuery || searchString.includes(searchQuery.toLowerCase());
  
      // Анализ текста платежа
      const paymentText = client.daysUntilPayment !== null ? (
        client.daysUntilPayment > 0 
          ? `через ${client.daysUntilPayment} дн.`
          : `просрочен на ${Math.abs(client.daysUntilPayment)} дн.`
      ) : '';
  
      // Фильтр по статусу платежей
      let matchesFilters = true;
      if (filters.overdue) {
        matchesFilters = !paymentText.includes('через') && client.daysUntilPayment !== null;
      } else if (filters.upcoming) {
        matchesFilters = paymentText.includes('через') && client.daysUntilPayment <= 10;
      }
  
      return matchesSearch && matchesFilters;
    });
  };

  if (isLoading) {
    return <div className="overdue-loading">Загрузка...</div>;
  }

  const filteredClients = getFilteredClients();

  return (
    <div className="overdue-container">
      <div className="overdue-header">
        <h1>Просроченные платежи</h1>
        
        <div className="search-container">
          <input
            type="text"
            placeholder="Поиск по ФИО, номеру договора или телефону..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
          />
        </div>
        
        <div className="filter-checkboxes">
          <label className="checkbox-label">
            <input
              type="checkbox"
              checked={filters.overdue}
              onChange={(e) => setFilters({
                overdue: e.target.checked,
                upcoming: e.target.checked ? false : filters.upcoming
              })}
            />
            Просроченные платежи
          </label>
          <label className="checkbox-label">
            <input
              type="checkbox"
              checked={filters.upcoming}
              onChange={(e) => setFilters({
                upcoming: e.target.checked,
                overdue: e.target.checked ? false : filters.overdue
              })}
            />
            Приближающиеся платежи
          </label>
        </div>
      </div>

      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>ФИО клиента</th>
              <th>Телефон</th>
              <th>Номер договора</th>
              <th>Следующий платеж</th>
              <th>Размер платежа</th>
              <th>Действия</th>
            </tr>
          </thead>
          <tbody>
            {filteredClients.map(client => (
              <tr 
                key={client.id}
                className={`client-row ${client.isOverdue ? 'overdue' : ''}`}
                onClick={() => handleClientClick(client.id)}
              >
                <td>{client.fullName}</td>
                <td>{client.phone}</td>
                <td>{client.contractNumber}</td>
                <td>
                  {client.nextPaymentDate ? new Date(client.nextPaymentDate).toLocaleDateString() : '-'}
                  {client.daysUntilPayment !== null && (
                    client.daysUntilPayment > 0 
                      ? ` (через ${client.daysUntilPayment} дн.)`
                      : ` (просрочен на ${Math.abs(client.daysUntilPayment)} дн.)`
                  )}
                </td>
                <td>{client.nextPaymentAmount?.toLocaleString()} ₽</td>
                <td>
                  <button
                    className={`toggle-problematic-btn ${client.isProblematic ? 'remove' : 'add'}`}
                    onClick={(e) => handleToggleProblematic(e, client)}
                  >
                    {client.isProblematic ? 'Снять проблему' : 'Отметить проблемным'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OverdueClients;